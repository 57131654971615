<template>
	<div class="p-reigster-consumer-error">
		reigster-error
	</div>
</template>

<script>
export default {
	data() {
		return {};
	}
}
</script>

<style lang="less">
.p-reigster-consumer-error {
}
</style>